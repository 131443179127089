<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    bottom
    left
    max-width="90%"
    min-width="275"
    offset-x
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template #activator="{ attrs, on }">
      <v-card
        id="settings"
        class="py-2 px-4"
        color="#0000004D"
        dark
        flat
        style="position: absolute; top: 0; right: 0;"
        width="65"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon large>
          mdi-cog
        </v-icon>
      </v-card>
    </template>

    <v-card
      class="py-2"
      style="background: #440099; border: 1px solid white;"
    >
      <base-title
        align="center"
        title="Theme"
        space="0"
        style="color: white"
      />

      <v-card-text>
        <v-slide-group
          v-model="currentThemeLogo"
          class="justify-center"
          show-arrows
        >
          <v-slide-item
            v-for="color in colors"
            :key="color"
            :value="color"
            class="justify-center"
          >
            <template #default="{ active, toggle }">
              <base-avatar
                :color="color"
                :outlined="active"
                class="ma-2 justify-center"
                size="48"
                style="cursor: pointer;"
                @click.stop="toggle"
              />
            </template>
          </v-slide-item>
        </v-slide-group>

        <v-divider
          class="my-6"
          style="background: white;"
        />

        <v-item-group
          class="d-flex justify-center"
        >
          <base-btn
            class="mb-0 mx-2"
            color="accent"
            dark
            @click="copy_text()"
          >
            Copy Text
          </base-btn>

          <base-btn
            class="mb-0 mx-2"
            color="accent"
            @click="copy_html()"
          >
            Copy Html
          </base-btn>
        </v-item-group>

        <v-divider
          class="my-6"
          style="background: white;"
        />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
  import copy from 'copy-to-clipboard'

  export default {
    name: 'HomeSettings',

    data () {
      return {
        colors: [
          '#6db640',
          '#74bfeb',
          '#f879bb',
          '#ff3019',
          '#31bca4',
        ],
        themes: [
          {
            logo: '#440099',
            signatureBg: '#0000004D',
          },
          {
            logo: '#440099',
            signatureBg: '#0000004D',
          },
        ],
        menu: false,
      }
    },

    computed: {
      currentThemeLogo: {
        get () {
          return this.$vuetify.theme.currentTheme.logo
        },
        set (val) {
          localStorage.logoColor = val

          this.$vuetify.theme.themes.dark.logo = val
          this.$vuetify.theme.themes.light.logo = val
          this.$vuetify.theme.themes.dark.signatureBg = '#440099'
          this.$vuetify.theme.themes.light.signatureBg = '#440099'
        },
      },
      currentThemeBg: {
        get () {
          return this.$vuetify.theme.currentTheme.signatureBg
        },
        set (val) {
          localStorage.signatureBgColor = '#440099'

          this.$vuetify.theme.themes.dark.signatureBg = '#440099'
          this.$vuetify.theme.themes.light.signatureBg = '#440099'
        },
      },
    },
    methods: {
      rgbToHex (match, r, g, b) {
        return '#' + [r, g, b].map(x => {
          const hex = parseInt(x).toString(16)
          return hex.length === 1 ? '0' + hex : hex
        }).join('')
      },
      minifyHTML (html) {
        html = html
          .replace(/>\s+</g, '><')
          .replace(/\s{2,}/g, ' ')
          .replace(/<!--[\s\S]*?-->/g, '')
          .trim()

        return html.replace(/rgb\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/g, this.rgbToHex)
      },
      copy_text () {
        const text = document.getElementById('abc')
        const selection = window.getSelection()
        const range = document.createRange()
        range.selectNodeContents(text)
        selection.removeAllRanges()
        selection.addRange(range)
        document.execCommand('copy')
        alert('Deine Signatur wurde in die Zwischenablage kopiert')
      },
      copy_html () {
        const salesForceHtml = document.getElementById('cba_sf').innerHTML
        copy(this.minifyHTML(salesForceHtml))
        // copy(salesForceHtml)
        alert('Deine Signatur wurde in die Zwischenablage kopiert')
      },
    },
  }
</script>
<style lang="scss">
.v-slide-group__content {
    justify-content: center;
  }
</style>
